import {
    PRICE_STATUS,
    PRICE_RULE_TYPES,
    AVAILABILITY_CODES,
    CHEMICAL_TEMPLATES
} from '../../constants/constants';
import { formatPriceForSEO } from '../../utils/utils';
export const PRODUCT_TYPE = 'Product';
const CONTEXT = 'https://schema.org';

const getUrl = (productUrl, xHostHeader) => {
    if (xHostHeader) {
        return `https://${xHostHeader}${productUrl}`;
    }
    return productUrl;
};

const getImageUrl = (image, xHostHeader) => {
    if (xHostHeader) {
        return `https://${xHostHeader}${image}`;
    }
    return image;
};

const getSchemaDetails = ({ selectedProduct, xHostHeader }) => {
    const schemaDetails = {
        '@context': CONTEXT,
        '@type': PRODUCT_TYPE,
        name: selectedProduct.productTitleV3,
        size: selectedProduct.productSize,
        description: selectedProduct.metaDescription,
        mpn: selectedProduct.catalogNumber,
        sku: selectedProduct.catalogNumber,
        url: getUrl(selectedProduct.productUrl, xHostHeader)
    };
    if (selectedProduct.images?.[0]?.path) {
        schemaDetails.image = getImageUrl(`${selectedProduct.images[0].path}-250.jpg`, xHostHeader);
    }
    if (selectedProduct.umbrellaBrand) {
        schemaDetails.Brand = selectedProduct.umbrellaBrand;
    }
    return schemaDetails;
};
const getAvailability = (availability, itemPrice) => {
    const IN_STOCK = `${CONTEXT}/InStock`;
    const OUT_OF_STOCK = `${CONTEXT}/OutOfStock`;
    let availabilityStatus = '';
    availability.availability?.forEach((avail) => {
        if (itemPrice.catalogNumber === avail.catalogNumber) {
            if (avail.availabilityCode === AVAILABILITY_CODES.IN_STOCK) {
                availabilityStatus = IN_STOCK;
            } else if (avail.availabilityCode === AVAILABILITY_CODES.OUT_OF_STOCK) {
                availabilityStatus = OUT_OF_STOCK;
            }
        }
    });
    return availabilityStatus;
};

const getSpecificationSchemaDetails = ({ selectedProduct }) => {
    const specifications = CHEMICAL_TEMPLATES.includes(selectedProduct.productTemplate) ? selectedProduct?.chemicalSpecifications : selectedProduct?.specifications;
    return {
        additionalProperty: specifications?.map((spec) => {
            return {
                name: spec?.displayName || spec?.name,
                value: spec?.values?.length ? spec.values.join(', ') : spec?.value
            };
        })
    }
};
const getPriceAndAvailability = ({
    prices,
    availability,
    selectedProduct,
    xHostHeader
}) => {
    const itemPrice = prices?.prices.find(price => price.catalogNumber === selectedProduct.catalogNumber);
    let offerData = {};
    let price = '';
    price = formatPriceForSEO(itemPrice.formattedPrice?.finalPrice);
    
    if (itemPrice?.priceAccess?.status === PRICE_STATUS.ORDERABLE) {
        offerData = {
            '@type': 'Offer',
            sku: itemPrice.catalogNumber,
            name: selectedProduct.productTitleV3,
            url: getUrl(
                `/order/catalog/product/${itemPrice.catalogNumber}`,
                xHostHeader
            ),
            price: price,
            priceCurrency: itemPrice.currency
        };
        if (
            itemPrice?.priceRuleType === PRICE_RULE_TYPES.ONLINE &&
            itemPrice?.onlinePriceEndDate
        ) {
            offerData.priceValidUntil = itemPrice?.onlinePriceEndDate;
        }

        const offersAvailability = getAvailability(
            availability,
            itemPrice
        );
        if (offersAvailability) {
            offerData.availability = offersAvailability;
        }
    }
    return { offers: offerData };
};

const getProductSchema = ({
    prices,
    availability,
    selectedProduct,
    xHostHeader
}) => {
    return {
        ...getSchemaDetails({ selectedProduct, xHostHeader }),
        ...getSpecificationSchemaDetails({ selectedProduct }),
        ...getPriceAndAvailability({
            prices,
            availability,
            selectedProduct,
            xHostHeader
        })
    };
};


export default getProductSchema;