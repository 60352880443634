import React, { Suspense, lazy } from 'react';
import useClientApp from '../../../hooks/useClientApp';
import ProductOverview from './ProductOverview/ProductOverview';
import StructureAndSpectra from './StructureAndSpectra/StructureAndSpectra';

const Video = lazy(() => import(/* webpackChunkName: "details" */'./Video/Video'));
const Figures = lazy(() => import(/* webpackChunkName: "details" */'./Figures/Figures'));
const Recommendations = lazy(() => import(/* webpackChunkName: "details" */'./Recommendations/Recommendations'));
const Documents = lazy(() => import(/* webpackChunkName: "details" */'./Documents/Documents'));
const FAQ = lazy(() => import(/* webpackChunkName: "details" */'./FAQ/FAQ'));
const SafetyAndHandling = lazy(() => import(/* webpackChunkName: "details" */'./SafetyAndHandling/SafetyAndHandling'));
const Citations = lazy(() => import(/* webpackChunkName: "details" */'./Citations/Citations'));
const OtherRecommendations = lazy(() => import(/* webpackChunkName: "details" */'./OtherRecommendations/OtherRecommendations'));

const NavContainers = () => {
  const { hydrated } = useClientApp();

  return (
    <div className="pdp-nav-containers">
      <ProductOverview />

      {hydrated && (
        <Suspense>
          <Figures />
        </Suspense>
      )}

      {hydrated && (
        <Suspense>
          <Video />
        </Suspense>
      )}

      <StructureAndSpectra />

      {hydrated && (
        <Suspense>
          <Recommendations />
        </Suspense>
      )}

      {hydrated && (
        <Suspense>
          <Documents />
        </Suspense>
      )}

      {hydrated && (
        <Suspense>
          <FAQ />
        </Suspense>
      )}

      {hydrated && (
        <Suspense>
          <Citations />
        </Suspense>
      )}

      {hydrated && (
        <Suspense>
          <SafetyAndHandling />
        </Suspense>
      )}

      {hydrated && (
        <Suspense>
          <OtherRecommendations />
        </Suspense>
      )}
    </div>
  );
};

export default NavContainers;